/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { CompanyId, URL } from '../../../app/config';
import axios from 'axios';

type ProjectType = {
  Title: string,
  Image: string,
  Tags: string,
  Link: string
};

export default function Project() {

  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('All');

  useEffect(()=> {
    axios.get(URL + "/api/content/Home/Projects/" + CompanyId.toString() ).then((res) => {
        const data = res.data.data;
        setProjects(data);
    });
  
  } ,[]);


  useEffect(() => {
    var tags: string[] = [];

    projects.map((item) => {
      var tgs = item.Tags.split(',');

     tgs.map((t) => {
      if(tags.indexOf(t) === -1){
        tags.push(t);
      }
     });
    });

    setTags(tags);

  }, [projects]);

  return (
    <section id="project" className="project bgwhite">
      <div className="container">
        <div className="row d-flex reveal" data-reveal="fadeInUp">
          <h2 className="title-two text-center">Our <span>Recent Projects</span></h2>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ul id="project-filters" className="reveal" data-reveal="fadeInUp">
              <li data-filter="*" onClick={() => {
                  setSelected('All');
                }} className={ 'All' === selected? 'filter-active' : '' } >All</li>
              {/* <li data-filter=".filter-web">Responsive Websites</li>
              <li data-filter=".filter-anly">Data Analysis</li>
              <li data-filter=".filter-opt">SEO Optimization</li>
              <li data-filter=".filter-min">Data Mining</li>
              <li data-filter=".filter-boot">Bootstrap 5</li> */}
              {tags.map(t => {
                return  <li data-filter=".filter-web" onClick={() => {
                  setSelected(t);
                }} className={ t == selected? 'filter-active' : '' }  >{ t }</li>;
              })}
            </ul>
          </div>
        </div>

        <div className="row project-container reveal" data-reveal="fadeInUp">
          
          {selected === 'All' ? projects.map(item => {
            return  <div className="col-lg-4 col-md-6 project-item filter-web">
            <div className="project-wrap">
              <img src={URL + '/public/Image/' + item.Image}  className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href={URL + '/public/Image/' + item.Image}  data-gallery="projectGallery" className="project-lightbox" title="Website 1">{item.Title}</a></h3>
                <div>
                  <a href={URL + '/public/Image/' + item.Image} data-gallery="projectGallery2" className="project-lightbox" title="Website 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href={item.Link} className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          } ) : projects.filter( x=> x.Tags.split(',').indexOf(selected) > -1).map(item => {
            return  <div className="col-lg-4 col-md-6 project-item filter-web">
            <div className="project-wrap">
              <img src={URL + '/public/Image/' + item.Image}  className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href={URL + '/public/Image/' + item.Image}  data-gallery="projectGallery" className="project-lightbox" title="Website 1">{item.Title}</a></h3>
                <div>
                  <a href={URL + '/public/Image/' + item.Image} data-gallery="projectGallery2" className="project-lightbox" title="Website 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href={item.Link} className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          } )  }
          
          {/* <div className="col-lg-4 col-md-6 project-item filter-web">
            <div className="project-wrap">
              <img src="images/projects/project1.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project1.jpg" data-gallery="projectGallery" className="project-lightbox" title="Website 1">Website 1</a></h3>
                <div>
                  <a href="images/projects/project1.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Website 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-web">
            <div className="project-wrap">
              <img src="images/projects/project2.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project2.jpg" data-gallery="projectGallery" className="project-lightbox" title="Website 2">Website 2</a></h3>
                <div>
                  <a href="images/projects/project2.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Website 2"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-anly">
            <div className="project-wrap">
              <img src="images/projects/project3.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project3.jpg" data-gallery="projectGallery" className="project-lightbox" title="Data Analysis 1">Data Analysis 1</a></h3>
                <div>
                  <a href="images/projects/project3.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Data Analysis 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-opt">
            <div className="project-wrap">
              <img src="images/projects/project4.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project4.jpg" data-gallery="projectGallery" className="project-lightbox" title="SEO Optimisation 1">SEO Optimisation 1</a></h3>
                <div>
                  <a href="images/projects/project4.jpg" data-gallery="projectGallery2" className="project-lightbox" title="SEO Optimisation 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-min">
            <div className="project-wrap">
              <img src="images/projects/project5.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project5.jpg" data-gallery="projectGallery" className="project-lightbox" title="Data Mining 1">Data Mining 1</a></h3>
                <div>
                  <a href="images/projects/project5.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Data Mining 1"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-min">
            <div className="project-wrap">
              <img src="images/projects/project6.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project6.jpg" data-gallery="projectGallery" className="project-lightbox" title="Data Mining 2">Data Mining 2</a></h3>
                <div>
                  <a href="images/projects/project6.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Data Mining 2"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-anly">
            <div className="project-wrap">
              <img src="images/projects/project7.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project7.jpg" data-gallery="projectGallery" className="project-lightbox" title="Data Analysis 2">Data Analysis 2</a></h3>
                <div>
                  <a href="images/projects/project7.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Data Analysis 2"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-boot">
            <div className="project-wrap">
              <img src="images/projects/project8.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project8.jpg" data-gallery="projectGallery" className="project-lightbox" title="Bootstrap 5">Bootstrap 5</a></h3>
                <div>
                  <a href="images/projects/project8.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Bootstrap 5"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 project-item filter-web">
            <div className="project-wrap">
              <img src="images/projects/project9.jpg" className="img-fluid" alt="" />
              <div className="project-info">
                <h3><a href="images/projects/project9.jpg" data-gallery="projectGallery" className="project-lightbox" title="Responsive Websites">Responsive Websites</a></h3>
                <div>
                  <a href="images/projects/project9.jpg" data-gallery="projectGallery2" className="project-lightbox" title="Responsive Websites"><i className="fa fa-search" aria-hidden="true"></i></a>
                  <a href="project-details.html" className="details-link" title="More Details"><i className="fa fa-link" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

/* eslint-disable prettier/prettier */

import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CompanyId } from "../../../app/config";

  type BlogType = {
    REF: string
    Title: string
    Description: string
    Banner: string
    Content: string
    Posted_User: string
    Posted_Date: string
  }

export default function Blogs(props: any) {

  const [loading, setLoading] = useState<boolean>(true);
  const [blogs, setBlogs] = useState<BlogType[]>([])
useEffect(()=> {
        
  axios.get("/api/content/Blogs/Blog/" + CompanyId.toString() ).then((res) => {

    // debugger;
      const data = res.data.data;
      setBlogs(data);
      setLoading(false);
  });

} ,[]);


if(loading)
  return(<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
  else
  return (
    
    <section id="blog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center mb-4 reveal" data-reveal="fadeInUp">
              <h2 className="title-two">
                From <span>the blog</span>
              </h2>
            </div>
          </div>

          <div className="text-center mb-2 reveal" data-reveal="fadeInUp">
            <p>
              Lorem ipsum dolor sit amet, consectetured adipiscing elit, sed do
              eiusmod tempor inci didunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exer citation ullamco laboris.
            </p>
          </div>
        </div>
        <div className="row">
{ blogs.map((blog) => {

  return (
    <>
      <div className="col-lg-4">
        <div className="blog-item reveal" data-reveal="fadeInUp">
          <div className="item">
            <div className="item-image mb-3">
              <img className="img-fluid" src="images/blog/blog1.jpg" alt="" />
            </div>
            <div className="item-content">
              <h3>{blog.Title}</h3>
              <p>{blog.Description}</p>
              <p className="postedby">
                Posted by
                <a href="#" className="theme-color">
                  {blog.Posted_User}
                </a>
                on <span className="theme-color">{blog.Posted_Date}</span>
              </p>
              <div className="ruler"></div>

              <Link to={"blogs/page/" + blog.REF}  className="btn btn-info">
                Read More
              </Link>
              {/* <a
                className="btn btn-info"
                // href="blog-description.html"
                href="#"
                onClick={() => {

                  navigation.navigate("blogs");
                }}
                target="_blank"
              >
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}) }


{/* 
          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog1.jpg"
                    alt=""
                  />
                </div>
                <div className="item-content">
                  <h3>Work Strategy</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog2.jpg"
                    alt=""
                  />
                </div>
                <div className="item-content">
                  <h3>Work Strategy</h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog3.jpg"
                    alt=""
                  />
                </div>

                <div className="item-content">
                  <h3>Work Strategy</h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

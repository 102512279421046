/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { CompanyId, URL } from '../../../app/config';
import axios from 'axios';

type ClientType = {
  Name: string,
  Image: string,
  Comment: string,
  Position: string
};

export default function Testimonial() {

  const [clients, setClients] = useState<ClientType[]>([]);
  
  useEffect(()=> {
    axios.get(URL + "/api/content/Home/Clients/" + CompanyId.toString() ).then((res) => {
        const data = res.data.data;
        setClients(data);
    });
  
  } ,[]);

  return (
    <section id="testimonials" className="testimonials bgwhite">
      <div className="container">
        <div className="row d-flex reveal" data-reveal="fadeInUp">
          <h2 className="title-two text-center">What Our <span>clients say?</span></h2>
          <div className="text-center quote"><span className="fa fa-quote-left"></span></div>
        </div>

        <div className="row testimonial_block swiper reveal" data-reveal="fadeInUp">
          <div className="swiper-wrapper mb-5">

            { clients.map(item => {
              return  <div className="testimonial_item swiper-slide">
              <p>{ item.Comment }</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src={ URL + '/public/Image/' + item.Image } className="img-fluid rounded-circle client-img" />
                <div className="name_desig">
                  <div className="name">{ item.Name }</div>
                  <div className="desig">{ item.Position }</div>
                </div>
              </div>
            </div>;
            }) }
            {/* <div className="testimonial_item swiper-slide">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src="images/testimonials/author1.jpg" className="rounded-circle" />
                <div className="name_desig">
                  <div className="name">Tina gomez</div>
                  <div className="desig">CEO, RBootstrap</div>
                </div>
              </div>
            </div>

            <div className="testimonial_item swiper-slide">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src="images/testimonials/author2.jpg" className="rounded-circle" />
                <div className="name_desig">
                  <div className="name">Tina gomez</div>
                  <div className="desig">CEO, RBootstrap</div>
                </div>
              </div>
            </div>

            <div className="testimonial_item swiper-slide">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src="images/testimonials/author3.jpg" className="rounded-circle" />
                <div className="name_desig">
                  <div className="name">Tina gomez</div>
                  <div className="desig">CEO, RBootstrap</div>
                </div>
              </div>
            </div>

            <div className="testimonial_item swiper-slide">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src="images/testimonials/author4.jpg" className="rounded-circle" />
                <div className="name_desig">
                  <div className="name">Tina gomez</div>
                  <div className="desig">CEO, RBootstrap</div>
                </div>
              </div>
            </div>

            <div className="testimonial_item swiper-slide">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.</p>
              <div className="testimonial_details d-flex">
                <img alt="Author1" src="images/testimonials/author1.jpg" className="rounded-circle" />
                <div className="name_desig">
                  <div className="name">Tina gomez</div>
                  <div className="desig">CEO, RBootstrap</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>

  )
}

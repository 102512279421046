/* eslint-disable prettier/prettier */
import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { CompanyId, URL } from '../../../app/config';

export default function AboutUs() {

  const [title, setTitle] = useState<string>('');
const [description, setDescription] = useState<string>('');
const [experience, setExperience] = useState<string>('');
const [awards, setAwards] = useState<string>('');
const [project, setProject] = useState<string>('');
const [clients, setClients] = useState<string>('');

  useEffect(()=> {
        
    axios.get(URL + "/api/content/Home/About/" + CompanyId.toString() ).then((res) => {

        const data = res.data.data[0];
        setTitle(data.Title);
        setDescription(data.Description);
        setAwards(data.Awards);
        setClients(data.Clients);
        setExperience(data.Experience);
        setProject(data.Project);
    });

} ,[]);

  return (
    <section id="about_us" className="bgwhite">
      <div className="container">
        <div className="row about_us d-flex">
          <div className="col-lg-6 reveal" data-reveal="fadeInLeft">
            <h2 className="title">{ title }</h2>
            <div className="mb-4 about_us_text">
      {description}
            </div>
          </div>
          <div className="col-lg-6 reveal" data-reveal="fadeInRight">
            <div className="row">
          
              <div className="col-sm-6 mb-4">
                <div className="text-center">
                  <i className="fa fa-star"></i>
                  <h2 data-purecounter-start="0" data-purecounter-end="120" data-purecounter-duration="2" className="purecounter stats-no">{experience}</h2>
                  <h5 className="text-uppercase">Years Experience</h5>
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="text-center">
                  <i className="fa fa-trophy"></i>
                  <h2 data-purecounter-start="0" data-purecounter-end="35" data-purecounter-duration="2" className="purecounter stats-no">{awards}</h2>
                  <h5 className="text-uppercase">Awards</h5>
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="text-center">
                  <i className="fa fa-database"></i>
                  <h2 data-purecounter-start="0" data-purecounter-end="357" data-purecounter-duration="2" className="purecounter stats-no">{project}</h2>
                  <h5 className="text-uppercase">Completed Projects</h5>
                </div>
              </div>

              <div className="col-sm-6 mb-4">
                <div className="text-center">
                  <i className="fa fa-users"></i>
                  <h2 data-purecounter-start="0" data-purecounter-end="305" data-purecounter-duration="2" className="purecounter stats-no">{clients}</h2>
                  <h5 className="text-uppercase">Clients</h5>
                </div>
              </div>

            </div>
          </div>
 
        </div>

      </div>

    </section>
  )
}

/* eslint-disable prettier/prettier */
import React from 'react';

export default function Footer() {
  return (
    <footer id="footer" className="text-white">

    <div id="footer_bottom" className="py-4">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-6 text-md-start text-white">&copy; Copyright <strong>BT Technologies</strong>. All Rights Reserved.</div>
  
          <div className="col-md-6 text-md-end text-white">Designed by <a target="_blank" href="https://soop.asia/">Soop Asia</a></div>
 
        </div>

      </div>
   
    </div>

  </footer>
  )
}

/* eslint-disable prettier/prettier */

import axios from "axios"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useParams } from "react-router"
import { CompanyId, URL } from "../../app/config"


  type BlogType = {
    REF: string
    Title: string
    Description: string
    Banner: string
    Content: string
    Posted_User: string
    Posted_Date: string
  }

export default function BlogPage() {
const { blog } = useParams();

    const [loading, setLoading] = useState<boolean>(true)
    const [blogs, setBlogs] = useState<BlogType>();
    useEffect(() => {
      axios
        .get("/api/content/Blogs/Blog/" + CompanyId.toString())
        .then((res) => {
          // debugger;
          const data = res.data.data
          var blogref: BlogType[] = data;

          setBlogs(blogref.filter((x) => x.REF == blog)[0])
          setLoading(false)
        })
    }, [])

    if(loading){
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )
    }
else
  return (
    <>
      <section className="ip_title">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="section-title-left">{blogs!.Title}</h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <a href="#">Blogs</a>
              </li>
              <li>{blogs!.Title}</li>
            </ol>
          </div>
        </div>
      </section>

      <div className="inner-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 justify-content-center">
              <img
                src={URL + "/public/Image/" + blogs!.Banner}
                className="img-fluid mb-3"
                title="Project 1"
              />
              <div dangerouslySetInnerHTML={{ __html: blogs!.Content }}></div>
            </div>
            <div className="col-lg-4">
              <div className="project-details">
                <h3>Blog Information</h3>
                <ul>
                  <li>
                    <strong>Title</strong>: {blogs!.Title}
                  </li>
                  <li>
                    <strong>Created by</strong>: {blogs!.Posted_User}
                  </li>
                  <li>
                    <strong>Posted date</strong>: {blogs!.Posted_Date}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

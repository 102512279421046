/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { CompanyId, URL } from '../../../app/config';
import axios from 'axios';

type ServiceType = {
  Title: string,
  Description: string
};

export default function Services() {

const [services, setServices] = useState<ServiceType[]>([]);
useEffect(()=> {
        
  axios.get(URL + "/api/content/Home/Services/" + CompanyId.toString() ).then((res) => {
      const data = res.data.data;
      setServices(data);
  });

} ,[]);

const zeroPad = (num:number, places:number) => String(num).padStart(places, '0')

  return (
    <section id="our_services">
    <div className="container">
      <div className="row our_services d-flex reveal" data-reveal="fadeInUp">
        <h2 className="title-two text-center">Our <span>Services</span></h2>
      </div>

      <div className="row reveal" data-reveal="fadeInUp">

{ services.map((item,i) => {
  return <div className="col-md-4 mb-4">
  <div className="service_content">
    <h3 className="serial_no">{zeroPad(i + 1,2)}.</h3>
    <h3 className="service_title">{item.Title}</h3>
    <p>{item.Description}</p>
  </div>
</div>;
}) }

        {/* <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">01.</h3>
            <h3 className="service_title">Business Consulting</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">02.</h3>
            <h3 className="service_title">Website Development</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">03.</h3>
            <h3 className="service_title">IT Consulting</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">04.</h3>
            <h3 className="service_title">Data Mining</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">05.</h3>
            <h3 className="service_title">SEO</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service_content">
            <h3 className="serial_no">06.</h3>
            <h3 className="service_title">Branding</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  )
}

/* eslint-disable prettier/prettier */
import React from 'react'

export default function Message() {
  return (
    <section id="company_message" className="company_message">
      <div className="container d-flex justify-content-between reveal" data-reveal="fadeInUp">
        <h3>We give you the best service possible! Want to try us? Please contact us!</h3>
        <div className="align-items-center d-flex"><a href="#contact_us" className="btn btn-primary">Contact Us</a></div>
      </div>

    </section>
  )
}

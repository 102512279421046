/* eslint-disable prettier/prettier */
import "bootstrap/scss/bootstrap.scss"
import { Route, Routes } from "react-router"
import { BrowserRouter } from "react-router-dom"
import "./App.css"
import "./animation.scss"
import BlogPage from "./pages/blogs/BlogPage"
import Home from "./pages/home/Home"
import Project from "./pages/project/Project"
import "./style.scss"
import Layout from "./templates/Layout"

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Project />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blogs/page/:blog" element={<BlogPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  )
}

export default App

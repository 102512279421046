/* eslint-disable prettier/prettier */
import React from 'react';
import contactBg from '../../../assets/images/contact-bg.jpg';
import { ADDRESS1, EMAIL, PHONE_NUMBER } from '../../../app/config';

export default function ContactUs() {
  return(
    <section id="contact_us">
      <div className="container contact_us">
        <div className="text-center mb-4 reveal" data-reveal="fadeInUp">
          <h2 className="title-two text-white">Contact <span>Us</span></h2>
        </div>

        <div className="row mt-1 d-flex justify-content-end">
          <div className="col-lg-5 reveal" data-reveal="fadeInLeft">
            <div className="info">
              <div className="address">
                <i className="fa fa-map-marker"></i>
                <h4>Location:</h4>
                <p>{ ADDRESS1 }</p>
              
              </div>

              <div className="email">
                <i className="fa-solid fa-envelope"></i>
                <h4>Email:</h4>
                <p>{ EMAIL }</p>
              </div>

              <div className="phone">
                <i className="fa-solid fa-phone"></i>
                <h4>Call:</h4>
                <p>{ PHONE_NUMBER }</p>
              </div>

              <div className="social-links d-md-flex justify-content-start text-md-center">
                <a href="#" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                <a href="#" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                <a href="#" className="instagram"><i className="fa-brands fa-instagram"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-center reveal" data-reveal="fadeInRight">
            <form className="requires-validation" noValidate id="ajaxform" action="#" method="post" name="myForm">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input className="form-control" id="full_name1" type="text" name="full_name" placeholder="Full Name" required />
                  <div className="invalid-feedback">Please enter your Full Name!</div>
                </div>
                <div className="col-md-6 mb-3">
                  <input className="form-control" id="email1" type="email" name="email" placeholder="E-mail Address" required />
                  <div className="invalid-feedback">Enter a valid Email ID!</div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <input className="form-control" id="phone1" type="text" name="phone" placeholder="Phone" required />
                  <div className="invalid-feedback">Please enter your Phone Number!</div>
                </div>

                <div className="col-md-6 mb-3">
                  <input className="form-control" id="subject1" type="text" name="subject" placeholder="Subject" required />
                  <div className="invalid-feedback">Please enter the Subject!</div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <textarea className="form-control" id="message1" name="message" placeholder="Enter Your Message" required rows={5}></textarea>
                  <div className="invalid-feedback">Please enter a message!</div>
                </div>
              </div>

              <div id="formoutput"></div>
 
              <button className="btn btn-info" id="submit" type="submit">Submit</button>
            </form>
          </div>
        </div>

      </div>
    
    </section>);
  
}

/* eslint-disable prettier/prettier */
import React, {useState, useEffect} from 'react'
import { Carousel } from 'react-bootstrap'
import axios from "axios";
import { CompanyId, URL } from '../../../app/config';
type BannerType = {
    Title:string,
    Description:string,
    Image:string
};

export default function Banner() {

    const [banners, setBanners] = useState<BannerType[]>([]);
    useEffect(()=> {
        
        axios.get(URL + "/api/content/Home/Banner/" + CompanyId.toString() ).then((res) => {
            const data = res.data.data;
            setBanners(data);
        });

    } ,[]);
    // const banners = [
    //     {
    //         title: "Welcome To Define",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.",
    //         path: "src/assets/images/slider/image1.jpg"
    //     },
    //     {
    //         title: "Welcome To Define",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.",
    //         path: "src/assets/images/slider/image1.jpg"
    //     },
    //     {
    //         title: "Welcome To Define",
    //         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporin cididulabore et dolore.",
    //         path: "src/assets/images/slider/image1.jpg"
    //     }
    // ];



  return (
    <section className='section-banner'>
    <Carousel id='maincarousel'>
        { banners.map((bn,i) => {
            return  <Carousel.Item key={i}>
                  <div className='animate zoomIn bg' style={{backgroundImage: "url(" + URL + '/public/Image/' + bn.Image + ")"}}>
                 <div className="carousel-container">
              <div className="carousel-data text-center">
              <h2 className="animate fadeInUp delay1 mb-4">{ bn.Title }</h2>
                <p className="lead animate fadeInUp delay1 mb-4 text-white">{ bn.Description }</p>
       
            <a href="#our_services" className="btn btn-info internal mb-2 animate fadeInUp delay1" tabIndex={-1}>Our Services<i className="fa fa-arrow-circle-right button-fa"></i></a>
                       <a href="#contact_us" className="btn btn-primary internal mb-2 animate fadeInUp delay1" tabIndex={-1}>Get Appointment<i className="fa fa-arrow-circle-right button-fa"></i></a>
            </div>
            </div>
            </div>
           </Carousel.Item>;
        }) }
    
  </Carousel>
  </section>
  )
}

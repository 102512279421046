/* eslint-disable prettier/prettier */
import Footer from "./Footer"
import Header from "./Header"

type layoutType = {
  children: any
}

export default function Layout({ children }: layoutType) {
  return <>
  <Header />
  { children }
  <Footer />
  </>
}

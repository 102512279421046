/* eslint-disable prettier/prettier */
import React from 'react'
import Banner from './components/Banner'
import AboutUs from './components/AboutUs'
import Services from './components/Services'
import Project from './components/Project'
import Message from './components/Message'
import Testimonial from './components/Testimonial'
import ContactUs from './components/ContactUs'
import Blogs from './components/Blogs'

export default function Home() {
  return (
   <>
   <Banner />
  <AboutUs />
  <Services />
  <Project />
  <Message />
  <Testimonial />
  <ContactUs />
  <Blogs />
   </>
  )
}
